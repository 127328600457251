const A6aKeys = {
  TITLE: "title",
  MESSAGE_FUNCTION: "Message Function",
  CHARGING_OPTION: "chargingOption",
  RECORD_STATUS: "Record Status",
  DOC_FORMAT: "Form Type",
  CARRIER_CODE: "carrierCode",
  UNIQUE_CONSIGNMENT_REF_NO: "Unique Consignment Reference Number",
  CCN: "ccn",
  SUPPLEMENTARY_REF_NO: "srn",
  ORIGINAL_CCN: "originalCCN",
  MOT: "mot",
  CUSTOMS_PROCEDURE: "customsProc",
  BOL_NO: "bolNo",
  SPECIAL_INS: "Special Instructions",
  SUPPLEMENTARY_REQ_IND: "supplementaryReqInd",
  BULK_SHIPMENT: "Bulk Shipment",
  A6_CONV_REF_NO: "a6ConvRefNo",
  VESSEL_NAME: "vesselName",
  VOYAGE_NO: "voyageNo",
  PORT_OF_LOADING: "Port of Loading",
  TERMINAL_OF_LOADING: "Terminal of Loading",
  PIER_OF_LOADING: "Pier of Loading",
  ESTIMATED_LOADING_DATE: "Estimated Loading Date",
  VESSEL_DEPARTURE_DATE: "Vessel Departure Date",
  CUSTOMS_OFFICE_OF_EXIT: "Customs Office of Exit",
  PORT_OF_ARRIVAL: "portOfArrival",
  PIER_OF_ARRIVAL: "Pier of Arrival",
  FOREIGN_PORT_OF_LADING: "foreignPortOfLading",
  CARGO_LOADING_CITY: "cargoLoadingCity",
  CARGO_LOADING_PORT: "cargoLoadingPort",
  CUSTOMS_OFF_OF_PORT_ARRIVAL: "customsOffOfPortArrival",
  CUSTOMS_OFF_OF_PORT_ARRIVAL_SUB_LOC_CODE: "customsOffOfPortArrivalSubLocCode",
  CUSTOMS_OFF_OF_MANIFEST_DESTINATION: "customsOffOfManifestDestination",
  CUSTOMS_OFF_OF_MANIFEST_DESTINATION_SUB_LOC_CODE: "customsOffOfManifestDestinationSubLocCode",
  CARGO_DESTINATION_COUNTRY: "cargoDestinationCountry",
  CARGO_DESTINATION_CITY: "cargoDestinationCity",
  CARGO_DESTINATION_PORT: "cargoDestinationPort",
  CUSTOMS_OFF_OF_DISCHARGE: "customsOffOfDischarge",
  ARRIVAL_TERMINAL: "arrivalTerminal",
  CARRIER_NAME: "carrierName",
  TERMINAL_NAME: "Terminal Name",
  PORT_OF_DISCHARGE: "Port of Discharge",
  CREATE_A6A: "createA6A",
  EDIT_A6A: "editA6A",
  VIEW_A6A: "viewA6A",
  A6A_TITLE: "A6ATitle",
  HEADER: "Header",
  SCHEDULE: "Schedule",
  A8A_A10_PRINTING_PURPOSES_ONLY: "a8aPrintingPurpose",
  RESPONSE: "Response Message",
  CONV_REFNO: "convRefNo",
  ERRORCODE: "Error Code",
  FIELDDESC: "Field Description",
  REJECT_TYPE: "Reject Type",
  ERROR_DESC:  "errorDesc",
  RESPONSE_TEXT: "Response Text",
  CREATIONDATETIME: "Created Date",
  TEMPLATE_FLAG: "Template Flag",
  TEMPLATE_NAME: "Template Name",
  D4_TITLE: "D4 Notices",
  SYSTEM_PROCESSED_DATE_TIME: "sysProcDateTime",
  CBSA_REF_NO: "cbsaRefNo",
  CBSA_PROCESSED_DATE_TIME: "cbsaProcDateTime",
  REMARKS: "Remarks",
  VIEW: "View",
  DOCUMENT_TYPE: "Document Type",
  A6A_TABLE: "A6A Table",
  SUBMIT_BUTTON: "Submit",
  CANCEL_BUTTON: "Cancel",
  SAVE_BUTTON: "Save",
  SEND_BUTTON: "Send",
  EDIT_BUTTON: "Edit",
  CLONE_BUTTON: "Clone",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecords",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  SUBMIT_SENT_RECORD: "sentSubmitRecords",
  SUBMIT_SUCCESS_MSG: "submittedRecordProcessing",
  SUBMIT_ERROR_MSG: "errorProcessData",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitDialogContent",
  SUBMIT_RECORD: "Submit Record",
  ERROR_CODE:  "Error Details",
  NORMAL_PRINT_A6A: "Normal Print",
  PRINT_A10: "Print A10",
  PRINT_A8A: "Print A8A",
  PRINT_MULTIPLE_RECORDS: "printMultipleRecord",
  DELETE_BUTTON: "Delete",
  DELETE_MULTIPLE_RECORDS: "deleteMultiple",
  MESSAGE_FUNCTION: "messageFuntion",
  SUPPLEMENTARY_MATCH: "supplementaryMatch",
  RISK_ASSESSMENT_STATUS: "riskAssessmentStatus",
  A6aExports: {
    TITLE: "a6aExports.title",
    EXPORT_TRANS_NO: "a6aExports.exportTransNo"
  },
  A6aPrevious: {
    TITLE: "a6aPrevious.title",
    PREVIOUS_CCN: "a6aPrevious.previousCCN"
  },
  TaciOcnA6aParties: {
    TITLE: "taciOcnA6aParties.title",
    PARTY_TYPE: "taciOcnA6aParties.partyType",
    PARTY_NAME1: "taciOcnA6aParties.partyName1",
    PARTY_NAME2: "taciOcnA6aParties.partyName2",
    ADDRESS1: "taciOcnA6aParties.address1",
    ADDRESS2: "taciOcnA6aParties.address2",
    CITY: "taciOcnA6aParties.city",
    STATE_CODE: "taciOcnA6aParties.stateCode",
    POSTAL_CODE: "taciOcnA6aParties.postalCode",
    COUNTRY_CODE: "taciOcnA6aParties.countryCode",
    CONTACT_PERSON: "taciOcnA6aParties.contactPerson",
    TEL_NO: "taciOcnA6aParties.telNo",
    PARTY_DROP_DOWN: "taciOcnA6aParties.partyList",
    SAVE_FLAG: "taciOcnA6aParties.savePartyInfo",
    PARTY_IDENTIFICATION: "taciOcnA6aParties.savePartyIden"
  },
  TaciOcnA6aShipments: {
    TITLE: "taciOcnA6aShipments.title",
    S_NO: "taciOcnA6aShipments.sno",
    PRODUCT_DROP_DOWN: "taciOcnA6aShipments.productList",
    SAVE_FLAG: "taciOcnA6aShipments.saveProductInfo",
    PRODUCT_IDENTIFICATION: "taciOcnA6aShipments.saveProductIden",
    ITEM_DESC1: "taciOcnA6aShipments.itemDesc1",
    ITEM_DESC2: "taciOcnA6aShipments.itemDesc2",
    ITEM_DESC3: "taciOcnA6aShipments.itemDesc3",
    ITEM_DESC4: "taciOcnA6aShipments.itemDesc4",
    ITEM_DESC5: "taciOcnA6aShipments.itemDesc5",
    ITEM_DESC6: "taciOcnA6aShipments.itemDesc6",
    ITEM_DESC7: "taciOcnA6aShipments.itemDesc7",
    ITEM_DESC8: "taciOcnA6aShipments.itemDesc8",
    ITEM_DESC9: "taciOcnA6aShipments.itemDesc9",
    NO_OF_PKGS: "taciOcnA6aShipments.noOfPckgs",
    PKG_UOM: "taciOcnA6aShipments.pkgUom",
    GROSS_WEIGHT: "taciOcnA6aShipments.grossWt",
    GROSSWEIGHT_UOM: "taciOcnA6aShipments.grossWtUom",
    VOLUME: "taciOcnA6aShipments.volume",
    VOLUME_UOM: "taciOcnA6aShipments.volumeUom",
    HS_CODE: "taciOcnA6aShipments.hsCode",
    UNDG_CODE: "taciOcnA6aShipments.undgCode",
    MARK_NO: "taciOcnA6aShipments.markNo",
    CONTAINER_NO: "taciOcnA6aShipments.containerNo"
  },
  TaciOcnA6aContainers: {
    TITLE: "taciOcnA6aContainers.title",
    CONTAINER_NO: "taciOcnA6aContainers.containerNo",
    CONTAINER_STATUS: "taciOcnA6aContainers.containerStatus",
    CONTAINERTYPE_SIZE: "taciOcnA6aContainers.containerType",
    CARRIAGE_COND: "taciOcnA6aContainers.carriageCond",
    SEAL_NO: "taciOcnA6aContainers.sealNo"
  },
  TaciOcnA6aSN: {
    TITLE: "taciOcnA6aSN.title",
    EMAIL: "taciOcnA6aSN.email",
    PHNO: "taciOcnA6aSN.phNo",
    NAME: "taciOcnA6aSN.name",
    SNTYPE: "taciOcnA6aSN.snType"
  }
}

export default A6aKeys
