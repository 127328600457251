const SbciInvoiceKeys = {
  ADD_INVOICE:"addInvoice",
  EDIT_INVOICE:"editInvoice",
  VIEW_INVOICE:"viewInvoice",
  ACTIVITY_LOGS:"activityLogs",
  LINE_ITEM_TAB:"lineItemTab",
  CLONE_BUTTON:"clone",
  CLONE_MULTIPLE_RECORDS:"cloneMultipeRecords",
  EXPORT_CSV:"exportCSV",
  MANIFEST_RESPONSE_TAB: "manifestResponseTab",
  EDIT_SENT_RECORDS : "editSentRecords",
  DELETE_SENT_RECORDS : "deleteSentRecords",
  EDIT_BUTTON : "editButton",
  DELETE_BUTTON : "deleteButton",
  DELETE_ALL_BUTTON : "deleteAllButton",
  VIEW_BUTTON : "viewButton",
  YES : "yes",
  NO : "no",
  BACK_TO_MANIFEST: "backToManifest",
  ATTACH_BUTTON : "attachButton",
  CANCEL_BUTTON : "cancelButton",
  ATTACH_INVOICE_MESSAGE : "attachInvoiceMessage",
  ATTACH_INVOICE_SUCCESS_MSG : "attachInvoiceSuccessMsg",
  CLONE_BUTTON : "cloneButton",
  DETACH_SENT_RECORD: "detachSentRecord",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_BUTTON: "submitButton",
  DETACH_MESSAGE: "detachMessage",
  DETACH_SUCCES_MSG: "detachSuccessMsg",
  SEND_TO_UPS: "sendToUPS",
  SEND_MANIFEST_WITH_INVOICES: "sendManifestWithInvoices",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  ERROR_MESSAGE: "errorMessage",
  CANCEL_BUTTON: "cancelButton",
  DETACH_BUTTON: "detachButton",
  PRINT:"print",
  ACTIVITY_LOGS_INFO:{
    TITLE: "activityLogsInfo.title",
    DATE_TIME: "activityLogsInfo.dateTime",
    LOGIN_USER_ID: "activityLogsInfo.loginUserId",
    ACTION_USER_ID: "activityLogsInfo.actionUserId",
    FUNCTION_CODE: "activityLogsInfo.functionCode",
    AUDIT_DESC: "activityLogsInfo.auditDesc",
    MODE: "activityLogsInfo.mode",
  },
  TITLE: "title",
  TITLE_IN_MANIFEST: "Invoice Header",
  ID_IN_MANIFEST: "Doc Ref. No",
  ID: "id",
  MANIFEST_ID: "manifestId",
  CORPID: "corpid",
  INVOICE_NO: "invoiceNo",
  INVOICE_DROP_DOWN: "selectInvoiceNo",
  INVOICE_DATE: "invoiceDate",
  INVOICE_TYPE: "invoiceType",
  ENTRY_TYPE: "entryType",
  ENTRY_NO: "entryNo",
  CLIENT_CODE: "clientCode",
  PORT_LADING_CODE: "portLadingCode",
  PORT_OF_DESTINATION: "portOfDestination",
  HEADER: "header",
  FOOTER: "footer",
  FREIGHT_CHARGE: "freightCharge",
  CAR_OR_TRAILER_NO: "carOrTrailerNo",
  CARRIER_CODE: "carrierCode",
  TOTAL_VALUE: "totalValue",
  GROSS_WEIGHT: "grossWeight",
  NET_WEIGHT: "netWeight",
  DESCRIPTION: "description",
  CTP_INITIATIVE: "ctpInitiative",
  STATUS:"status",
  SUBMISSION_TYPE: "submissionType",
  RESPONSE_DATETIME: "responseDatetime",
  RESPONSE_ID: "responseId",
  REMAINDER_FLAG: "remainderFlag",
  REMAINDER_NOTE: "remainderNote",
  SHIPMENT_QTY: "shipmentQty",
  LOCKED_BY: "lockedBy",
  LOCKED_DATE: "lockedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  TEMPLATE_NAME:"templateName",
  TEMPLATE_FLAG:"templateFlag",
  SAVE_AS_TEMPLATE:"saveAsTemplate",
  HIDE_TEXT:"hideText",
  SHOW_TEXT:"showText",
  FOOTER_TEXT:"footerText",
  SUBMIT_BUTTON:"submitButton",
  PREVIOUS_STEP:"previousStep",
  SAVE:"save",
  SAVE_AND_PROCEED:"saveAndProceed",
  UNLOCK:"unlock",
  CREATE_ACE_SHIPMENT:"createACEShipment",
  PRINT_ALL_FORMS:"printAllForms",
  SNO:"sNo",
  ADD_LINE_ITEM:"addLineItem",
  CLEAR:"clear",
  TEMPLATE:"template",
  ATTACH_BUTTON: "attachButton",
  TradeParty: {
    TITLE: "tradeParty.title",
    TRADE_PARTY_ID: "tradeParty.tradePartyId",
    TRADE_PARTY_INVOICE_ID: "tradeParty.tradePartyInvoiceId",
    TRADE_PARTY_CORPID: "tradeParty.tradePartyCorpid",
    USERID: "tradeParty.userid",
    PARTY_IDN: "tradeParty.partyIdn",
    PARTY_NAME: "tradeParty.partyName",
    PARTY_TYPE: "tradeParty.partyType",
    UPS_CLIENT_ID: "tradeParty.upsClientId",
    MID: "tradeParty.mid",
    IRS: "tradeParty.irs",
    DIVISION: "tradeParty.division",
    LOC_PORT_CODE: "tradeParty.locPortCode",
    PO_BOX_NO: "tradeParty.poBoxNo",
    ADDRESS: "tradeParty.address",
    CITY: "tradeParty.city",
    STATE_CODE: "tradeParty.stateCode",
    COUNTRY_CODE: "tradeParty.countryCode",
    POSTAL_CODE: "tradeParty.postalCode",
    TELPH_NUM: "tradeParty.telphNum",
    CONTACT_FAX: "tradeParty.contactFax",
    CONTACT_EMAIL: "tradeParty.contactEmail",
    TAX_ID_NUMBER: "tradeParty.taxIdNumber",
    CONTACT_NAME: "tradeParty.contactName",
    SAVE_FLAG: "tradeParty.saveFlag",
    PARTY_DROP_DOWN:"tradeParty.partyDropDown"
  },
  BolForm: {
    TITLE: "bolForm.title",
    BOL_FORM_ID: "bolForm.bolFormId",
    BOL_FORM_INVOICE_ID: "bolForm.bolFormInvoiceId",
    CONTAINER_NO: "bolForm.containerNo",
    FREIGHT_CHARGES: "bolForm.freightCharges",
    PLACE_OF_LADING: "bolForm.placeOfLading",
    DELIVERY_INSTRUCTIONS: "bolForm.deliveryInstructions",
    COD_AMOUNT: "bolForm.codAmount",
    CURRENCY: "bolForm.currency",
    SHIPMENT_DECLARE_VALUE: "bolForm.shipmentDeclareValue",
    RELEASE_VALUE: "bolForm.releaseValue",
    CHARGE_TO: "bolForm.chargeTo",
    TOT_SHPMNT_CUBIC_FT: "bolForm.totShpmntCubicFt",
    FREIGHT_CLASS: "bolForm.freightClass"
  },
  NaftaForm: {
    TITLE: "naftaForm.title",
    NAFTA_FORM_ID: "naftaForm.naftaFormId",
    NAFTA_FORM_INVOICE_ID: "naftaForm.naftaFormInvoiceId",
    DATE_FROM: "naftaForm.dateFrom",
    DATE_TO: "naftaForm.dateTo",
    COMPANY: "naftaForm.company",
    NAFTA_TITLE: "naftaForm.naftaTitle",
    NAFTA_DATE: "naftaForm.naftaDate",
    PARTY_NAME: "naftaForm.partyName",
    TELE_NO: "naftaForm.teleNo",
    CONTACT_FAX: "naftaForm.contactFax",
    EMAIL: "naftaForm.email",
    CERTIFIER_TYPE: "naftaForm.certifierType",
    TITLE_CERTIFIER_DETAILS: "naftaForm.titleCertifierDetails"
  },
  PackingListForm: {
    TITLE: "packingListForm.title",
    PACKING_LIST_FORM_ID: "packingListForm.packingListFormId",
    PACKING_LIST_FORM_INVOICE_ID: "packingListForm.packingListFormInvoiceId",
    OTHER_REF_NO: "packingListForm.otherRefNo",
    RESPONSIBLE_FOR_CUSTOMS: "packingListForm.responsibleForCustoms",
    INSTRUCTIONS_TO_BROKER: "packingListForm.instructionsToBroker",
    CONTAINERIZED: "packingListForm.containerized",
    FLIGHT_DETAILS: "packingListForm.flightDetails",
    SPECIAL_INSTRUCTIONS: "packingListForm.specialInstructions",
    PLACE_OF_DELIVERY: "packingListForm.placeOfDelivery",
    PORT_OF_LOADING: "packingListForm.portOfLoading",
    BILL_CHARGES_TO: "packingListForm.billChargesTo",
    DATE_SHIPPED: "packingListForm.dateShipped"
  },
  AdditionalCharges: {
    TITLE: "additionalCharges.title",
    ADDITIONAL_CHARGES_ID: "additionalCharges.additionalChargesId",
    ADDITIONAL_CHARGES_INVOICE_ID: "additionalCharges.additionalChargesInvoiceId",
    CHARGE_CODE: "additionalCharges.chargeCode",
    AMOUNT: "additionalCharges.amount"
  },
  IdentificationInfo: {
    TITLE: "identificationInfo.title",
    IDENTIFICATION_INFO_ID: "identificationInfo.identificationInfoId",
    IDENTIFICATION_INFO_INVOICE_ID: "identificationInfo.identificationInfoInvoiceId",
    IDENTIFICATION_TYPE: "identificationInfo.identificationType",
    IDENTIFICATION_NUMBER: "identificationInfo.identificationNumber"
  },
  LaceyActForm: {
    TITLE: "laceyActForm.title",
    LACEY_ACT_FORM_ID: "laceyActForm.laceyActFormId",
    LACEY_ACT_FORM_INVOICE_ID: "laceyActForm.laceyActFormInvoiceId",
    CONTAINER_NO: "laceyActForm.containerNo",
    PRINT_NAME: "laceyActForm.printName",
    DESC_OF_MERCHANDISE: "laceyActForm.descOfMerchandise",
    LACEY_DATE: "laceyActForm.laceyDate",
    ESTIMATED_ARRIVAL_DATE: "laceyActForm.estimatedArrivalDate"
  },
  LaceyActDetails: {
    TITLE: "laceyActDetails.title",
    LACEY_ACT_FORM: "laceyActDetails._laceyActFormId",
    LACEY_ACT_DETAILS_ID: "laceyActDetails.laceyActDetailsId",
    LACEY_ACT_DETAILS_LACEY_ID: "laceyActDetails.laceyActDetailsLaceyId",
    HTS_NO: "laceyActDetails.htsNo",
    ENTERED_VALUE: "laceyActDetails.enteredValue",
    COMPONENT_OF_ARTICLE: "laceyActDetails.componentOfArticle",
    GENUS_NAME: "laceyActDetails.genusName",
    SPECIES_NAME: "laceyActDetails.speciesName",
    COUNTRY: "laceyActDetails.country",
    QUANTITY: "laceyActDetails.quantity",
    UOM: "laceyActDetails.uom",
    PERCENT_OF_RECYCLE: "laceyActDetails.percentOfRecycle"
  },
  CiForm: {
    ADDITIONAL_INFO:'ciForm.additionalInfo',
    ADDITIONAL_FORM_INFO:'ciForm.additionalFormInfo',
    TITLE: "ciForm.title",
    CI_FORM_ID: "ciForm.ciFormId",
    CI_FORM_INVOICE_ID: "ciForm.ciFormInvoiceId",
    INVOICE_NUMBER: "ciForm.invoiceNumber",
    PARTY_BILLED: "ciForm.partyBilled",
    PARTY_BILLED_OTHER: "ciForm.partyBilledOther",
    SHIPMENT_DATE: "ciForm.shipmentDate",
    ARRIVAL_DATETIME: "ciForm.arrivalDatetime",
    COUNTRY_OF_EXPORT: "ciForm.countryOfExport",
    EXPORT_STATE_CODE: "ciForm.exportStateCode",
    DESTINATION_COUNTRY: "ciForm.destinationCountry",
    DESTINATION_STATE_CODE: "ciForm.destinationStateCode",
    TRANSPORT_TERM_CODE: "ciForm.transportTermCode",
    SHIPPER_REG_NO: "ciForm.shipperRegNo",
    TRANSACTION_PARTIES: "ciForm.transactionParties",
    FFD_PURCHASE_PRICE: "ciForm.ffdPurchasePrice",
    FBI_PURCHASE_PRICE: "ciForm.fbiPurchasePrice",
    BFI_PURCHASE_PRICE: "ciForm.bfiPurchasePrice",
    DFI_PURCHASE_PRICE: "ciForm.dfiPurchasePrice",
    GOODS_SOLD: "ciForm.goodsSold",
    MARK_PACK_LOAD: "ciForm.markPackLoad",
    TERMS_OF_PAYMENT: "ciForm.termsOfPayment",
    REASON_FOR_EXPORT: "ciForm.reasonForExport",
    NAFTA_DECLARATION: "ciForm.naftaDeclaration",
    TSCA_DECLARATION: "ciForm.tscaDeclaration",
    US_PROFORMA: "ciForm.usProforma",
    US_GOODS: "ciForm.usGoods",
    US_SHIP_EXP: "ciForm.usShipExp",
    US_GOODS10219: "ciForm.usGoods10219",
    FDA_SUB: "ciForm.fdaSub",
    DECL_NEW_STMT: "ciForm.declNewStmt",
    TITLE_FOB_RELATED_INSTRUCTIONS:"ciForm.titleFOBRelatedInstructions",
    TITLE_YES_NO_QUESTIONNAIRE:"ciForm.titleYesNoQuestionnaire",
    TITLE_DECLARATIONS:"ciForm.titleDeclarations"
  },
  CargoSummary: {
    TITLE: "cargoSummary.title",
    CI_FORM: "cargoSummary._ciFormId",
    CARGO_SUMMARY_ID: "cargoSummary.cargoSummaryId",
    CARGO_SUMMARY_CI_FORM_ID: "cargoSummary.cargoSummaryCiFormId",
    REFERENCE: "cargoSummary.reference",
    REFERENCE_NUMBER: "cargoSummary.referenceNumber"
  },
  TscaForm: {
    TITLE: "tscaForm.title",
    TSCA_FORM_ID: "tscaForm.tscaFormId",
    TSCA_FORM_INVOICE_ID: "tscaForm.tscaFormInvoiceId",
    NAME: "tscaForm.name",
    TSCA_DATE: "tscaForm.tscaDate",
    TSCA_TITLE: "tscaForm.tscaTitle",
    PHONE: "tscaForm.phone",
    COMPANY_NAME: "tscaForm.companyName",
    ADDRESS1: "tscaForm.address1",
    ADDRESS2: "tscaForm.address2",
    CITYSTATEZIP: "tscaForm.citystatezip",
    TSCA_CERT_CHOICE: "tscaForm.tscaCertChoice",
    RESEARCH_CERT: "tscaForm.researchCert"
  },
  TscaDetails: {
    TITLE: "tscaDetails.title",
    TSCA_FORM: "tscaDetails._tscaFormId",
    TSCA_DETAILS_ID: "tscaDetails.tscaDetailsId",
    TSCA_DETAILS_TSCA_ID: "tscaDetails.tscaDetailsTscaId",
    CHEMICAL_NAME: "tscaDetails.chemicalName",
    SERVICES: "tscaDetails.services",
    REG_NUMBER: "tscaDetails.regNumber"
  },
  LineItem: {
    "DETAILS_TITLE":"lineItem.detailsTitle",
    TITLE: "lineItem.title",
    LINE_ITEM_ID: "lineItem.lineItemId",
    LINE_ITEM_INVOICE_ID: "lineItem.lineItemInvoiceId",
    PRODUCT_ID: "lineItem.productId",
    PART_NO: "lineItem.partNo",
    HTS_CODE: "lineItem.htsCode",
    PART_DESC: "lineItem.partDesc",
    PART_TYPE: "lineItem.partType",
    PURCHASE_ORDER_NO: "lineItem.purchaseOrderNo",
    PART_REF_NO: "lineItem.partRefNo",
    BIND_RULING_ID_TYPE: "lineItem.bindRulingIdType",
    BIND_RULING_ID: "lineItem.bindRulingId",
    SPCL_PROG_IND: "lineItem.spclProgInd",
    UNIT_VALUE: "lineItem.unitValue",
    COUNTRY: "lineItem.country",
    QTY: "lineItem.qty",
    QTY_UOM: "lineItem.qtyUom",
    UOM1_FACTOR1: "lineItem.uom1Factor1",
    UOM2_FACTOR1: "lineItem.uom2Factor1",
    GROSS_WT: "lineItem.grossWt",
    NET_WT: "lineItem.netWt",
    ADDITIONAL_DESCRIPTION: "lineItem.additionalDescription",
    PRINT_HTS_CODE: "lineItem.printHtsCode",
    PRINT_UNIT_VALUE: "lineItem.printUnitValue",
    PACKING9801_HTS_CODE: "lineItem.packing9801HtsCode",
    PACKING9801_UNIT_VALUE: "lineItem.packing9801UnitValue",
    USMCA_CRITERIA: "lineItem.usmcaCriteria",
    DATE_RANGE_FROM: "lineItem.dateRangeFrom",
    DATE_RANGE_TO: "lineItem.dateRangeTo",
    IS_PRODUCER: "lineItem.isProducer",
    INCLUDE_PROD_USMCA: "lineItem.includeProdUsmca",
    SAVE_FLAG: "lineItem.saveFlag",
    PRODUCT_DROP_DOWN:"lineItem.productDropDown"
  },
  LineitemOgaCode: {
    TITLE: "lineitemOgaCode.title",
    LINE_ITEM: "lineitemOgaCode._lineItemId",
    LINEITEM_OGA_CODE_ID: "lineitemOgaCode.lineitemOgaCodeId",
    LINEITEM_OGA_CODE_LINE_ITEM_ID: "lineitemOgaCode.lineitemOgaCodeLineItemId",
    OGA_CODE: "lineitemOgaCode.ogaCode",
    OGA_DOC_NO: "lineitemOgaCode.ogaDocNo"
  },
  FccTab: {
    TITLE: "fccTab.title",
    LINE_ITEM: "fccTab._lineItemId",
    FCC_TAB_ID: "fccTab.fccTabId",
    FCC_TAB_LINE_ITEM_ID: "fccTab.fccTabLineItemId",
    IMPORT_CONDITION_NO: "fccTab.importConditionNo",
    FCC_IDENTIFIER: "fccTab.fccIdentifier",
    IMPORT_CONDITION: "fccTab.importCondition",
    FCC_REQUIREMENT: "fccTab.fccRequirement",
    TRADE_NAME: "fccTab.tradeName",
    WITHHOLD_INSPECTION: "fccTab.withholdInspection"
  },
  Goodsreturned: {
    TITLE: "goodsreturned.title",
    LINE_ITEM: "goodsreturned._lineItemId",
    GOODSRETURNED_ID: "goodsreturned.goodsreturnedId",
    GOODSRETURNED_LINE_ITEM_ID: "goodsreturned.goodsreturnedLineItemId",
    HTS_CODE: "goodsreturned.htsCode",
    DUTIABLE_UNIT_VALUE: "goodsreturned.dutiableUnitValue",
    NON_DUTIABLE_UNIT_VALUE: "goodsreturned.nonDutiableUnitValue",
    PACKING_UNIT_VALUE: "goodsreturned.packingUnitValue",
    GOODSRETURNED_UNIT_VALUE: "goodsreturned.goodsreturnedUnitValue",
    GOODSRETURNED_LINE_VALUE: "goodsreturned.goodsreturnedLineValue"
  },
  LaceyAct: {
    TITLE: "laceyAct.title",
    LINE_ITEM: "laceyAct._lineItemId",
    LACEY_ACT_ID: "laceyAct.laceyActId",
    LACEY_ACT_LINE_ITEM_ID: "laceyAct.laceyActLineItemId",
    PGA_CODES: "laceyAct.pgaCodes",
    PGA_1: "laceyAct.pga1",
    PGA_2: "laceyAct.pga2",
    PGA_3: "laceyAct.pga3",
    PGA_4: "laceyAct.pga4",
    PGA_5: "laceyAct.pga5",
    PGA_6: "laceyAct.pga6",
    INTENDED_USE_CODE: "laceyAct.intendedUseCode",
    INTENDED_USE_DESC: "laceyAct.intendedUseDesc",
    INGREDIENT_NAME: "laceyAct.ingredientName",
    QTY_OF_MATERIAL: "laceyAct.qtyOfMaterial",
    QTY_OF_MATERIAL_UOM: "laceyAct.qtyOfMaterialUom",
    PERCENT_INGREDIENT: "laceyAct.percentIngredient",
    PLANT_SCIENTIFIC_NAME: "laceyAct.plantScientificName",
    SCIENTIFIC_SPECIES_NAME: "laceyAct.scientificSpeciesName",
    SOURCE_CODE: "laceyAct.sourceCode",
    COUNTRY_CODE: "laceyAct.countryCode"
  },
  UsPacking: {
    TITLE: "usPacking.title",
    LINE_ITEM: "usPacking._lineItemId",
    US_PACKING_ID: "usPacking.usPackingId",
    US_PACKING_LINE_ITEM_ID: "usPacking.usPackingLineItemId",
    HTS_CODE: "usPacking.htsCode",
    PACKING_VALUE: "usPacking.packingValue",
    QTY: "usPacking.qty",
    QTY_UOM: "usPacking.qtyUom",
    UOM1_FACTOR3: "usPacking.uom1Factor3",
    UOM2_FACTOR3: "usPacking.uom2Factor3",
    ASSEMBLY_PRICE: "usPacking.assemblyPrice"
  },
  LineitemAddiInfo: {
    TITLE: "lineitemAddiInfo.title",
    LINE_ITEM: "lineitemAddiInfo._lineItemId",
    LINEITEM_ADDI_INFO_ID: "lineitemAddiInfo.lineitemAddiInfoId",
    LINEITEM_ADDI_INFO_LINE_ITEM_ID: "lineitemAddiInfo.lineitemAddiInfoLineItemId",
    ADDITIONAL_INFO_TYPE: "lineitemAddiInfo.additionalInfoType",
    ADDITIONAL_INFO_NO: "lineitemAddiInfo.additionalInfoNo"
  },
  Produce: {
    TITLE: "produce.title",
    LINE_ITEM: "produce._lineItemId",
    PRODUCE_ID: "produce.produceId",
    PRODUCE_LINE_ITEM_ID: "produce.produceLineItemId",
    SCIENTIFIC_NAME: "produce.scientificName",
    DESCRIPTION: "produce.description",
    PRODUCE_SIZE: "produce.produceSize",
    PRODUCT_CODE: "produce.productCode"
  },
  Hts9802Repair: {
    TITLE_HTS_HEADER9802_INFO:"hts9802Repair.titleHTSHeader9802Info",
    TITLE: "hts9802Repair.title",
    LINE_ITEM: "hts9802Repair._lineItemId",
    HTS9802_REPAIR_ID: "hts9802Repair.hts9802RepairId",
    HTS9802_REPAIR_LINE_ITEM_ID: "hts9802Repair.hts9802RepairLineItemId",
    INVOICE_NO: "hts9802Repair.invoiceNo",
    HTS_CODE: "hts9802Repair.htsCode",
    UNIT_VALUE: "hts9802Repair.unitValue",
    HTS9802_REPAIR_QTY: "hts9802Repair.hts9802RepairQty",
    HTS9802_REPAIR_QTY_UOM: "hts9802Repair.hts9802RepairQtyUom",
    UOM1_FACTOR2: "hts9802Repair.uom1Factor2",
    UOM2_FACTOR2: "hts9802Repair.uom2Factor2",
    SPECIAL_HTS_CODE: "hts9802Repair.specialHtsCode",
    CATEGORY_NO: "hts9802Repair.categoryNo",
    CERT_OF_REG_NO: "hts9802Repair.certOfRegNo",
    REPAIR_QTY: "hts9802Repair.repairQty",
    REPAIR_QTY_UOM: "hts9802Repair.repairQtyUom",
    REWORK_DATE: "hts9802Repair.reworkDate",
    NO_OF_CONTAINERS1: "hts9802Repair.noOfContainers1",
    CONTAINER_TYPE1: "hts9802Repair.containerType1",
    NO_OF_CONTAINERS2: "hts9802Repair.noOfContainers2",
    CONTAINER_TYPE2: "hts9802Repair.containerType2",
    TITLE_REPAIR_PACKING_CONTAINER_INFO:"hts9802Repair.titleRepairPackingContainerInfo"
  },
  FdaTab: {
    TITLE: "fdaTab.title",
    LINE_ITEM: "fdaTab._lineItemId",
    FDA_TAB_ID: "fdaTab.fdaTabId",
    FDA_TAB_LINE_ITEM_ID: "fdaTab.fdaTabLineItemId",
    PRODUCT_CODE: "fdaTab.productCode",
    STORAGE_STATUS: "fdaTab.storageStatus",
    COUNTRY: "fdaTab.country",
    MANUFACTURER_ID: "fdaTab.manufacturerId",
    SHIPPER_ID: "fdaTab.shipperId",
    MARKER: "fdaTab.marker"
  },
  FdaQtyUom: {
    TITLE: "fdaQtyUom.title",
    FDA_TAB: "fdaQtyUom._fdaTabId",
    FDA_QTY_UOM_ID: "fdaQtyUom.fdaQtyUomId",
    FDA_QTY_UOM_FDA_TAB_ID: "fdaQtyUom.fdaQtyUomFdaTabId",
    QUANTITY: "fdaQtyUom.quantity",
    UOM: "fdaQtyUom.uom"
  },
  FdaAffirmation: {
    TITLE: "fdaAffirmation.title",
    FDA_TAB: "fdaAffirmation._fdaTabId",
    FDA_AFFIRMATION_ID: "fdaAffirmation.fdaAffirmationId",
    FDA_AFFIRMATION_FDA_TAB_ID: "fdaAffirmation.fdaAffirmationFdaTabId",
    AFFIRMATION_CODE: "fdaAffirmation.affirmationCode",
    AFFIRMATION_QUALIFIER: "fdaAffirmation.affirmationQualifier"
  },
  ValidationMessage:{
    DATE_TO_LATER_THAN_DATE_FROM : "validationMessage.dateToLaterThanDateFrom",
    ERROR_MSG_ALPHA_NUMERIC: "validationMessage.errMsgAlphaNumeric",
    ERROR_MSG_ALPHA_NUMERIC_NO_SPECIAL_CHAR: "validationMessage.errMsgAlphaNumericNoSpecialChar",
    ERROR_MSG_NUMERIC: "validationMessage.errMsgNumeric",
    ERROR_MSG_MAX_LENGTH: "validationMessage.errMsgMaxLength",
    ERROR_MSG_EMAIL: "validationMessage.errMsgEmail",
    ERROR_MSG_MIN_LENGTH: "validationMessage.errMsgMinLength",
    ERROR_MSG_ALPHA:"validationMessage.errMsgAlpha",
    ERROR_MSG_DECIMAL1:"validationMessage.errMsgDecimal1",
    ERROR_MSG_DECIMAL2:"validationMessage.errMsgDecimal2",
    ERROR_MSG_DECIMAL3:"validationMessage.errMsgDecimal3",
  },
  CSV_MAX_FILE_SIZE : "csvMaxFileSize",
  CSV_FILE_TYPE : "csvFileType",
  CSV_INVALID_FILE_TYPE : "csvInvalidFileType",
  CSV_INVALID_FILE : "csvInvalidFile",
  CSV_MESSAGE_TYPE : "csvMessageType",
  CSV_SELECT_MESSAGE_TYPE : "csvSelectMessageType",
  CSV_MESSAGE_TYPE_INV : "csvMessageTypeInv",
  CSV_MESSAGE_TYPE_PM : "csvMessageTypePm",
  CSV_FILE_UPLOAD_SUCCESS : "csvFileUploadSuccess",
  CSV_FILE_UPLOAD_ERROR : "csvFileUploadError",
  UPLOAD_BUTTON : "uploadButton",
  CSV_FILE_DETAILS : "csvFileDetails",
  CSV_FILE_NAME : "csvFileName",
  CSV_FILE_LAST_MODIFIED : "csvFileLastModified",
  CSV_SELECT_FILE : "csvSelectFile",
  CSV_TITLE : "csvTitle",
}

export default SbciInvoiceKeys
