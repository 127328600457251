import React, { createContext, useState } from 'react'

const FileForUserContext = createContext()

function FileForUserProvider({ children }){
    const [fileForUserValue, setFileForUserValue] = useState({})

    return (
        <FileForUserContext.Provider value={{ setFileForUserValue,fileForUserValue}}>
        { children }
        </FileForUserContext.Provider>
    )
}

export default FileForUserContext
export { FileForUserProvider }