const NbciUsInvoiceKeys = {
  TITLE: "title",
  INVOICE_NUMBER: "invoiceNumber",
  INVOICE_NUMBER2: "invoiceNumber2",
  SCAC_CODE: "scacCode",
  INVOICE_DATE: "invoiceDate",
  CARRIER_NAME: "carrierName",
  PURCHASE_ORDER_NUMBER: "purchaseOrderNumber",
  SHIPMENT_CONTROL_NUMBER: "shipmentControlNumber",
  CURRENCY: "currency",
  FOREIGN_PORT_OF_EXIT: "foreignPortOfExit",
  TOTAL_SHIPMENT_QUANTITY: "totalShipmentQuantity",
  PORTOF_ENTRY: "portofEntry",
  TOTAL_SHIPMENT_QUANTITY_U_O_M: "totalShipmentQuantityUOM",
  TRANSPORTATION_MODE: "transportationMode",
  TOTAL_ITEMS_GROSS_WEIGHT: "totalItemsGrossWeight",
  TOTAL_ITEMS_GROSS_WEIGHT_UOM: "totalItemsGrossWeightUom",
  ENTRY_NUMBER: "entryNumber",
  TOTAL_ITEMS_NET_WEIGHT: "totalItemsNetWeight",
  TOTAL_ITEMS_NET_WEIGHT_UOM: "totalItemsNetWeightUom",
  STATUS: "status",
  SNO: "sno",
  EDIT_INVOICE:"editUsInvoice",
  ACTIVITY_LOGS:"activityLogs",
  EXPORT_CARRIER:"exportCarrier",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_MULTIPLE_RECORDS: "deleteMultiple",
  DELETE_DRAFT_TEMPLATE: "deleteDraftTemplate",
  EDIT_SENT_RECORDS: "editSentRecords",
  SUBMIT_BUTTON: "submitButton",
  STATUS: "status",
  MESSAGE_CODE: "messageCode",
  ID: "id",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  CLONE_MULTIPLE_RECORDS:"cloneMultiple",
  RESPONSE_TITLE:"responseTitle",
  ACTIVITYLOG_TITLE: "activityLogs",
  LOGIN_USER_ID:"loginUserID",
  FUNCTION_CODE:"FunctionCode",
  AUDIT_DESC:"AuditDesc",
  RESPONSE_TITLE:"inbondResponse",
  BATCH_ID:"batchID",
  CBSA_PROCESSING_DATE:"cbsaProcDate",
  CBP_REF_NO:"cbpRefNo",
  RESPONSE_CODE:"responseCode",
  RESPONSE_TEXT:"responseText",
  RESPONSE_DATE:"responseDate",
  SUBMISSION_TYPE:"submissionType",
  RESPONSE_TAB:"response",
  ERROR_CODE: "responseErrorDetails",
  RECEIVED_DATE: "responseReceivedDate",
  ERRORCODE: "responseErrorCode",
  REJECT_TYPE: "responseRejectType",
  ERROR_DESC: "responseErrorDesc",
  SYSTEM_PROCESSED_DATE_TIME: "responseSystemDate",
  REMARKS: "responseRemarks",
  VIEW: "responseView",
  DOCUMENT_TYPE: "responseDocType",
  MESSAGE_FUNCTION: "responseMessageFunction",
  ERROR_TEXT_LABEL: "errorTextLabel",
  DESCRIPTION: "description",
  SAVE_FLAG:"saveFlag",
  TEMPLATE_FLAG:"templateFlag",
  TEMPLATE_NAME:"templateName",
  TradeParty: {
    PARTY_ID:"tradeParty.partyid",
    TITLE: "tradeParty.title",
    PARTY_NAME: "tradeParty.partyName",
    PARTY_TYPE: "tradeParty.partyType",
    PARTY_IDENTIFIER_CODE: "tradeParty.partyIdentifierCode",
    PARTY_IDENTIFIER_NUMBER: "tradeParty.partyIdentifierNumber",
    PARTY_SCAC_CODE: "tradeParty.partyScacCode",
    PARTY_ADDRESS: "tradeParty.partyAddress",
    PARTY_CITY: "tradeParty.partyCity",
    PARTY_STATE: "tradeParty.partyState",
    PARTY_COUNTRY: "tradeParty.partyCountry",
    PARTY_POSTAL_CODE: "tradeParty.partyPostalCode",
    PARTY_LOCAL_CONTACT_NAME: "tradeParty.partyLocalContactName",
    PARTY_TELEPHONE_NUMBER: "tradeParty.partyTelephoneNumber",
    PARTY_FAX: "tradeParty.partyFax",
    DBA: "tradeParty.dba",
    FCC740_ID: "tradeParty.fcc740Id",
    FDA_REG_NUM: "tradeParty.fdaRegNum",
    PARTY_EMAIL: "tradeParty.partyEmail",
    NOTE_LABEL:"tradeParty.noteLabel",
    NOTE_PARTY1:"tradeParty.noteParty1",
    NOTE_PARTY2:"tradeParty.noteParty2"
  },
  InvoiceDetails: {
    TITLE: "invoiceDetails.title",
    PRODUCT_ID:"invoiceDetails.productid",
    PART_NUMBER: "invoiceDetails.partNumber",
    PRODUCT_DESCRIPTION: "invoiceDetails.productDescription",
    FDA_PRODUCT_CODE: "invoiceDetails.fdaProductCode",
    COUNTRY_OF_ORIGIN_CODE: "invoiceDetails.countryOfOriginCode",
    STATE_OF_ORIGIN: "invoiceDetails.stateOfOrigin",
    QUANTITY_INVOICED: "invoiceDetails.quantityInvoiced",
    UOM_QUANTITY: "invoiceDetails.uomQuantity",
    HTS_CODE: "invoiceDetails.htsCode",
    UNIT_PRICE: "invoiceDetails.unitPrice",
    NET_WEIGHT: "invoiceDetails.netWeight",
    NET_WEIGHT_UOM: "invoiceDetails.netWeightUom",
    GROSS_WEIGHT: "invoiceDetails.grossWeight",
    GROSS_WEIGHT_UOM: "invoiceDetails.grossWeightUom",
    LINE_ITEM_TOTAL: "invoiceDetails.lineItemTotal",
    MARK_PACK_LOAD: "invoiceDetails.markPackLoad",
    NO_OF_PACKAGES: "invoiceDetails.noOfPackages",
    PACKAGE_TYPE: "invoiceDetails.packageType",
    PRODUCT_SIZE: "invoiceDetails.productSize",
    ADMNAME: "invoiceDetails.admname",
    ADM_TELEPHONE_NUMBER: "invoiceDetails.admTelephoneNumber",
    NAFTA_CRITERIA: "invoiceDetails.naftaCriteria",
    DATE_RANGE_FROM: "invoiceDetails.dateRangeFrom",
    DATE_RANGE_TO: "invoiceDetails.dateRangeTo",
    NET_COST: "invoiceDetails.netCost",
    IS_PRODUCER: "invoiceDetails.isProducer",
    LINE_AMOUNT: "invoiceDetails.lineAmount",
    INCLUDE_PROD_NAFTA: "invoiceDetails.includeProdNafta",
    NOT_A_PRDUCER_OF_GOODS: "invoiceDetails.notAPrducerOfGoods",
    TEMPLATE_INDICATOR: "invoiceDetails.templateIndicator",
    MANUFACTURING_LABEL: "invoiceDetails.manufacturingLabel",
    MANUFACTURING_PARTY_NAME: "invoiceDetails.manufacturingPartyName",
    MANUFACTURING_M_I_D: "invoiceDetails.manufacturingMID",
    MANUFACTURING_F_D_A_REG_NUM: "invoiceDetails.manufacturingFDARegNum",
    GROWER_LABEL: "invoiceDetails.growerLabel",
    GROWER_PARTY_NAME: "invoiceDetails.growerPartyName",
    GROWER_M_I_D: "invoiceDetails.growerMID",
    GROWER_F_D_A_REG_NUMBER: "invoiceDetails.growerFDARegNumber",
    CONSOLIDATOR_LABEL: "invoiceDetails.consolidatorLabel",
    CONSOLIDATOR_PARTY_NAME: "invoiceDetails.consolidatorPartyName",
    CONSOLIDATOR_M_I_D: "invoiceDetails.consolidatorMID",
    CONSOLIDATOR_F_D_A_REG_NUMBER: "invoiceDetails.consolidatorFDARegNumber",  
    CONS_PARTY_TITLE: "invoiceDetails.partyTitle",
    PARTY_ID: "invoiceDetails.consPartyID",
    PARTY_NAME: "invoiceDetails.partyName",
    PARTY_TYPE: "invoiceDetails.partyType",
    PARTY_IDENTIFIER_CODE: "invoiceDetails.partyIdentifierCode",
    PARTY_IDENTIFIER_NUMBER: "invoiceDetails.partyIdentifierNumber",
    PARTY_FDA_REG_NO: "invoiceDetails.partyFDANO",
    PARTY_ADDRESS: "invoiceDetails.partyAddress",
    PARTY_CITY: "invoiceDetails.partyCity",
    PARTY_STATE: "invoiceDetails.partyState",
    PARTY_COUNTRY: "invoiceDetails.partyCountry",
    PARTY_POSTAL_CODE: "invoiceDetails.partyPostalCode",
    PARTY_LOCAL_CONTACT_NAME: "invoiceDetails.partyLocalContactName",
    PARTY_TELEPHONE_NUMBER: "invoiceDetails.partyTelephoneNumber",
    PARTY_FAX: "invoiceDetails.partyFax",
    DBA: "invoiceDetails.dba",
    FCC740_ID: "invoiceDetails.fcc740Id",
    FDA_REG_NUM: "invoiceDetails.fdaRegNum",
    PARTY_EMAIL: "invoiceDetails.partyEmail",
    NOTE_LABEL:"invoiceDetails.noteLabel",
    NOTE_PARTY1:"invoiceDetails.noteParty1"
  },
  NBCIUSInvoiceDetailsReference: {
    TITLE: "nBCIUSInvoiceDetailsReference.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsReference._invoiceDetailsId",
    REFERENCE_TYPE: "nBCIUSInvoiceDetailsReference.referenceType",
    REFERENCE_IDENTIFICATION: "nBCIUSInvoiceDetailsReference.referenceIdentification",
    REF_DESCRIPTION: "nBCIUSInvoiceDetailsReference.refDescription"
  },
  NBCIUSInvoiceDetailsService: {
    TITLE: "nBCIUSInvoiceDetailsService.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsService._invoiceDetailsId",
    ADDITION_DEDUCTION_CODE: "nBCIUSInvoiceDetailsService.additionDeductionCode",
    AMOUNT: "nBCIUSInvoiceDetailsService.amount",
    ADDITION_DEDUCTION_IND: "nBCIUSInvoiceDetailsService.additionDeductionInd"
  },
  NBCIUSInvoiceDetailsDOTForm: {
    TITLE: "nBCIUSInvoiceDetailsDOTForm.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsDOTForm._invoiceDetailsId",
    PORT_CODE: "nBCIUSInvoiceDetailsDOTForm.portCode",
    BEHICLE_MAKE: "nBCIUSInvoiceDetailsDOTForm.behicleMake",
    VEHICLE_ELIGIBILITY_NO: "nBCIUSInvoiceDetailsDOTForm.vehicleEligibilityNo",
    EQIP_DESC: "nBCIUSInvoiceDetailsDOTForm.eqipDesc",
    CUSTOMS_ENTRY_NO: "nBCIUSInvoiceDetailsDOTForm.customsEntryNo",
    MODEL: "nBCIUSInvoiceDetailsDOTForm.model",
    ENTRY_DATE: "nBCIUSInvoiceDetailsDOTForm.entryDate",
    YEAR: "nBCIUSInvoiceDetailsDOTForm.year",
    VEHICLE_IDENTIFICATION_NO: "nBCIUSInvoiceDetailsDOTForm.vehicleIdentificationNo",
    REGISTER_IMPORTER_NAME: "nBCIUSInvoiceDetailsDOTForm.registerImporterName",
    N_H_T_S_A_REG_NO: "nBCIUSInvoiceDetailsDOTForm.nhtsaregNo",
    IMPORTER_NAME: "nBCIUSInvoiceDetailsDOTForm.importerName",
    IMPORTER_ADDRESS: "nBCIUSInvoiceDetailsDOTForm.importerAddress",
    DECLARANT_NAME: "nBCIUSInvoiceDetailsDOTForm.declarantName",
    DECLARANT_ADDRESS: "nBCIUSInvoiceDetailsDOTForm.declarantAddress",
    DATE_SIGNED: "nBCIUSInvoiceDetailsDOTForm.dateSigned",
    SAFETY1: "nBCIUSInvoiceDetailsDOTForm.safety1",
    SAFETY2_A: "nBCIUSInvoiceDetailsDOTForm.safety2A",
    SAFETY2_B: "nBCIUSInvoiceDetailsDOTForm.safety2B",
    SAFETY3: "nBCIUSInvoiceDetailsDOTForm.safety3",
    SAFETY4: "nBCIUSInvoiceDetailsDOTForm.safety4",
    SAFETY5: "nBCIUSInvoiceDetailsDOTForm.safety5",
    SAFETY6: "nBCIUSInvoiceDetailsDOTForm.safety6",
    SAFETY7: "nBCIUSInvoiceDetailsDOTForm.safety7",
    SAFETY8: "nBCIUSInvoiceDetailsDOTForm.safety8",
    SAFETY9: "nBCIUSInvoiceDetailsDOTForm.safety9",
    SAFETY10: "nBCIUSInvoiceDetailsDOTForm.safety10",
    SAFETY11: "nBCIUSInvoiceDetailsDOTForm.safety11",
    SAFETY12: "nBCIUSInvoiceDetailsDOTForm.safety12",
    SAFETY13: "nBCIUSInvoiceDetailsDOTForm.safety13"
  },
  NBCIUSInvoiceDetailsFDAForm: {
    TITLE: "nBCIUSInvoiceDetailsFDAForm.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsFDAForm._invoiceDetailsId",
    PRODUCT_DESC: "nBCIUSInvoiceDetailsFDAForm.productDesc",
    QUANTITY: "nBCIUSInvoiceDetailsFDAForm.quantity",
    MODEL_NO: "nBCIUSInvoiceDetailsFDAForm.modelNo",
    BRAND_NAME: "nBCIUSInvoiceDetailsFDAForm.brandName"
  },
  NBCIUSInvoiceDetailsFCCForm: {
    TITLE: "nBCIUSInvoiceDetailsFCCForm.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsFCCForm._invoiceDetailsId",
    DEVICE_MODEL: "nBCIUSInvoiceDetailsFCCForm.deviceModel",
    TRADE_NAME: "nBCIUSInvoiceDetailsFCCForm.tradeName",
    FCC_ID: "nBCIUSInvoiceDetailsFCCForm.fccId",
    EQUIP_DESC: "nBCIUSInvoiceDetailsFCCForm.equipDesc",
    DATE_OF_ENTRY: "nBCIUSInvoiceDetailsFCCForm.dateOfEntry",
    ENTRY_N_O: "nBCIUSInvoiceDetailsFCCForm.entryNO",
    DETAILS_PORT_OF_ENTRY: "nBCIUSInvoiceDetailsFCCForm.detailsPortOfEntry",
    HTS_NO: "nBCIUSInvoiceDetailsFCCForm.htsNo",
    QUANTITY_OF_ITEM: "nBCIUSInvoiceDetailsFCCForm.quantityOfItem"
  },
  NBCIUSInvoiceDetailsEPA35201Form: {
    TITLE: "nBCIUSInvoiceDetailsEPA35201Form.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsEPA35201Form._invoiceDetailsId",
    PORT_CODE: "nBCIUSInvoiceDetailsEPA35201Form.portCode",
    ENTRY_DATE: "nBCIUSInvoiceDetailsEPA35201Form.entryDate",
    ENTRY_NO: "nBCIUSInvoiceDetailsEPA35201Form.entryNo",
    VIN_NUMBER: "nBCIUSInvoiceDetailsEPA35201Form.vinNumber",
    MANUFACTURE_DATE: "nBCIUSInvoiceDetailsEPA35201Form.manufactureDate",
    MANUFACTURER: "nBCIUSInvoiceDetailsEPA35201Form.manufacturer",
    MODEL: "nBCIUSInvoiceDetailsEPA35201Form.model",
    MODEL_YEAR: "nBCIUSInvoiceDetailsEPA35201Form.modelYear",
    EXPIRY_DATE_OF_CERT: "nBCIUSInvoiceDetailsEPA35201Form.expiryDateOfCert",
    DATE_FOR_FORM: "nBCIUSInvoiceDetailsEPA35201Form.dateForForm",
    NAME_COMPANY_PHONE: "nBCIUSInvoiceDetailsEPA35201Form.nameCompanyPhone",
    VEHICLE_TYPE_B: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeB",
    VEHICLE_TYPE_F: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeF",
    VEHICLE_TYPE_EE: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeEe",
    VEHICLE_TYPE_FF: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeFf",
    VEHICLE_TYPE_M: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeM",
    VEHICLE_TYPE_E: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeE",
    VEHICLE_TYPE_L: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeL",
    VEHICLE_TYPE_U: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeU",
    VEHICLE_TYPE_W: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeW",
    VEHICLE_TYPE_Y: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeY",
    VEHICLE_TYPE_G: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeG",
    VEHICLE_TYPE_I: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeI",
    VEHICLE_TYPE_K: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeK",
    VEHICLE_TYPE_N: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeN",
    VEHICLE_TYPE_O: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeO",
    VEHICLE_TYPE_A: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeA",
    VEHICLE_TYPE_C: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeC",
    VEHICLE_TYPE_J: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeJ",
    VEHICLE_TYPE_Z: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeZ",
    VEHICLE_TYPE_H: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeH",
    VEHICLE_TYPE_Q: "nBCIUSInvoiceDetailsEPA35201Form.vehicleTypeQ"
  },
  NBCIUSInvoiceDetailsEPA352021Form: {
    TITLE: "nBCIUSInvoiceDetailsEPA352021Form.title",
    INVOICE_DETAILS: "nBCIUSInvoiceDetailsEPA352021Form._invoiceDetailsId",
    PORT_CODE: "nBCIUSInvoiceDetailsEPA352021Form.portCode",
    ENTITY_DATE: "nBCIUSInvoiceDetailsEPA352021Form.entityDate",
    ENTRY_NO: "nBCIUSInvoiceDetailsEPA352021Form.entryNo",
    ENGINE_BUILD_DATE: "nBCIUSInvoiceDetailsEPA352021Form.engineBuildDate",
    ON_ENGINE: "nBCIUSInvoiceDetailsEPA352021Form.onEngine",
    OTHER: "nBCIUSInvoiceDetailsEPA352021Form.other",
    ENGINE_MANUFACTURER: "nBCIUSInvoiceDetailsEPA352021Form.engineManufacturer",
    VEHICLE_MANUFACTURER: "nBCIUSInvoiceDetailsEPA352021Form.vehicleManufacturer",
    PRINTED_NAME: "nBCIUSInvoiceDetailsEPA352021Form.printedName",
    PRINTED_DATE: "nBCIUSInvoiceDetailsEPA352021Form.printedDate",
    EQUIPMENT_TYPE_A: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeA",
    EQUIPMENT_TYPE_B: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeB",
    EQUIPMENT_TYPE_C: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeC",
    EQUIPMENT_TYPE_D: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeD",
    EQUIPMENT_TYPE_E: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeE",
    EQUIPMENT_TYPE_F: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeF",
    EQUIPMENT_TYPE_G: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeG",
    EQUIPMENT_TYPE_H: "nBCIUSInvoiceDetailsEPA352021Form.equipmentTypeH",
    EQUIPMENT_TYPE1: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType1",
    EQUIPMENT_TYPE2: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType2",
    EQUIPMENT_TYPE3: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType3",
    EQUIPMENT_TYPE4: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType4",
    EQUIPMENT_TYPE5: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType5",
    EQUIPMENT_TYPE6: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType6",
    EQUIPMENT_TYPE7: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType7",
    EQUIPMENT_TYPE8: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType8",
    EQUIPMENT_TYPE9: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType9",
    EQUIPMENT_TYPE10: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType10",
    EQUIPMENT_TYPE11: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType11",
    EQUIPMENT_TYPE12: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType12",
    EQUIPMENT_TYPE13: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType13",
    EQUIPMENT_TYPE14: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType14",
    EQUIPMENT_TYPE15: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType15",
    EQUIPMENT_TYPE16: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType16",
    EQUIPMENT_TYPE17: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType17",
    EQUIPMENT_TYPE18: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType18",
    EQUIPMENT_TYPE19: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType19",
    EQUIPMENT_TYPE20: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType20",
    EQUIPMENT_TYPE21: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType21",
    EQUIPMENT_TYPE22: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType22",
    EQUIPMENT_TYPE23: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType23",
    EQUIPMENT_TYPE24: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType24",
    EQUIPMENT_TYPE25: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType25",
    EQUIPMENT_TYPE26_A: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType26A",
    EQUIPMENT_TYPE26_B: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType26B",
    EQUIPMENT_TYPE26_C: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType26C",
    EQUIPMENT_TYPE24_POWER: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType24Power",
    EQUIPMENT_TYPE24_WATTS: "nBCIUSInvoiceDetailsEPA352021Form.equipmentType24Watts"
  },
  InvoiceSummary: {
    TITLE: "invoiceSummary.title",
    TOTAL_INVOICE: "invoiceSummary.totalInvoice"
  },
  NbciUSInvoiceShipmentSummary: {
    TITLE: "nbciUSInvoiceShipmentSummary.title",
    INVOICE_SUMMARY: "nbciUSInvoiceShipmentSummary._invoiceSummaryId",
    NO_OF_PACKAGES: "nbciUSInvoiceShipmentSummary.noOfPackages",
    PACKAGE_TYPE: "nbciUSInvoiceShipmentSummary.packageType",
    PACKAGE_DIM_L: "nbciUSInvoiceShipmentSummary.packageDimL",
    PACKAGE_DIM_W: "nbciUSInvoiceShipmentSummary.packageDimW",
    PACKAGE_DIM_H: "nbciUSInvoiceShipmentSummary.packageDimH",
    DIMENSIONS_U_O_M: "nbciUSInvoiceShipmentSummary.dimensionsUOM",
    PROD_DESC: "nbciUSInvoiceShipmentSummary.prodDesc",
    GROSS_WEIGHT: "nbciUSInvoiceShipmentSummary.grossWeight",
    GROSS_WEIGHT_U_O_M: "nbciUSInvoiceShipmentSummary.grossWeightUOM",
    FREIGHT_CLASS: "nbciUSInvoiceShipmentSummary.freightClass"
  },
  LaceyAct: {
    TITLE: "laceyAct.title",
    CONTAINER_NO: "laceyAct.containerNo",
    PRINT_NAME: "laceyAct.printName",
    DESC_OF_MERCHANDISE: "laceyAct.descOfMerchandise",
    LACEY_DATE: "laceyAct.laceyDate",
    ESTIMATED_ARRIVAL_DATE: "laceyAct.estimatedArrivalDate"
  },
  NBCIUSInvoiceLaceyActDetails: {
    TITLE: "nBCIUSInvoiceLaceyActDetails.title",
    LACEY_ACT: "nBCIUSInvoiceLaceyActDetails._laceyActId",
    HTS_NO: "nBCIUSInvoiceLaceyActDetails.htsNo",
    ENTERED_VALUE: "nBCIUSInvoiceLaceyActDetails.enteredValue",
    COMPONENT_OF_ARTICLE: "nBCIUSInvoiceLaceyActDetails.componentOfArticle",
    GENUS_GAME: "nBCIUSInvoiceLaceyActDetails.genusGame",
    SPECIES_NAME: "nBCIUSInvoiceLaceyActDetails.speciesName",
    COUNTRY: "nBCIUSInvoiceLaceyActDetails.country",
    QUANTITY: "nBCIUSInvoiceLaceyActDetails.quantity",
    UOM: "nBCIUSInvoiceLaceyActDetails.uom",
    PERCENT_OF_RECYCLE: "nBCIUSInvoiceLaceyActDetails.percentOfRecycle"
  },
  PackingList: {
    TITLE: "packingList.title",
    INVOICE_ID: "packingList.invoiceId",
    OTHER_REF_NO: "packingList.otherRefNo",
    RESPONSIBLE_FOR_CUSTOMS: "packingList.responsibleForCustoms",
    INSTRUCTIONS_TO_BROKER: "packingList.instructionsToBroker",
    CONTAINERIZED: "packingList.containerized",
    BILL_CHARGES_TO: "packingList.billChargesTo",
    PORT_OF_LOADING: "packingList.portOfLoading",
    FLIGHT_DETAILS: "packingList.flightDetails",
    PLACE_OF_DELIVERY: "packingList.placeOfDelivery",
    SPECIAL_INSTRUCTIONS: "packingList.specialInstructions"
  },
  TSCAForm: {
    TITLE: "tSCAForm.title",
    INVOICE_ID: "tSCAForm.invoiceId",
    NAME: "tSCAForm.name",
    TSCA_DATE: "tSCAForm.tscaDate",
    TITLE: "tSCAForm.title",
    PHONE: "tSCAForm.phone",
    COMPANY_NAME: "tSCAForm.companyName",
    ADDRESS1: "tSCAForm.address1",
    ADDRESS2: "tSCAForm.address2",
    CITY_STATE_ZIP: "tSCAForm.cityStateZip",
    CHOICE1_OR2: "tSCAForm.choice1Or2",
    CHOICE3: "tSCAForm.choice3",
    CREATED_BY: "tSCAForm.createdBy",
    CREATED_DATE: "tSCAForm.createdDate",
    UPDATED_BY: "tSCAForm.updatedBy",
    UPDATED_DATE: "tSCAForm.updatedDate",
    VERSION: "tSCAForm.version",
    CHOICE4: "tSCAForm.choice4",
    EMAIL: "tSCAForm.email"
  },
  NBCIUSInvoiceTCSADetails: {
    TITLE: "nBCIUSInvoiceTCSADetails.title",
    T_S_C_A_FORM: "nBCIUSInvoiceTCSADetails._tSCAFormId",
    TSCA_ID: "nBCIUSInvoiceTCSADetails.tscaId",
    CHEMICAL_NAME: "nBCIUSInvoiceTCSADetails.chemicalName",
    SERVICES: "nBCIUSInvoiceTCSADetails.services",
    REG_NUMBER: "nBCIUSInvoiceTCSADetails.regNumber"
  },
  BolForm: {
    TITLE: "bolForm.title",
    INVOICE_ID: "bolForm.invoiceId",
    CONTAINER_NO: "bolForm.containerNo",
    FREIGHT_CHARGES: "bolForm.freightCharges",
    PLACE_OF_LADDING: "bolForm.placeOfLadding",
    DELIVERY_INSTRUCTIONS: "bolForm.deliveryInstructions",
    COD_AMOUNT: "bolForm.codAmount",
    CURRENCY: "bolForm.currency",
    DECLARED_VALUE_OF_SHIPMENT: "bolForm.declaredValueOfShipment",
    CHARGE_TO: "bolForm.chargeTo",
    RELEASE_VALUE: "bolForm.releaseValue"
  },
  FDABTAForm: {
    TITLE: "fDABTAForm.title",
    INVOICE_ID: "fDABTAForm.invoiceId",
    US_CUSTOM_BROKER: "fDABTAForm.usCustomBroker",
    OTHER_REF_NO: "fDABTAForm.otherRefNo",
    PRIOR_NOTICE_NO: "fDABTAForm.priorNoticeNo",
    BILL_CUSTOM_CHARGS_SH: "fDABTAForm.billCustomChargsSh",
    BILL_CUSTOM_CHARGS_CN: "fDABTAForm.billCustomChargsCn",
    BILL_CUSTOM_CHARGS_OT: "fDABTAForm.billCustomChargsOt",
    VESSEL_NAME: "fDABTAForm.vesselName",
    DATE_OF_EXPORT: "fDABTAForm.dateOfExport",
    SPECIFY_OTHERS: "fDABTAForm.specifyOther",
    FLIGHT_NO: "fDABTAForm.flightNo",
    TRIP_NO: "fDABTAForm.tripNo",
    FRIEGHT_INCLUDED: "fDABTAForm.frieghtIncluded",
    EXPORT_PERMIT_NUMBER: "fDABTAForm.exportPermitNumber"
  },
  UsAPHISForm: {
    TITLE: "usAPHISForm.title",
    PORT_OF_EMBARKATION: "usAPHISForm.portOfEmbarkation",
    COUNTRY_OF_HEALTH_CARE: "usAPHISForm.countryOfHealthCare",
    SHIPPING_DATE: "usAPHISForm.shippingDate",
    ARRIVAL_DATE: "usAPHISForm.arrivalDate",
    USAPHIS_NAME: "usAPHISForm.usaphisName",
    USAPHIS_ADDRESS: "usAPHISForm.usaphisAddress",
    USAPHIS_CITY: "usAPHISForm.usaphisCity",
    USAPHIS_STATE_CODE: "usAPHISForm.usaphisStateCode",
    USAPHIS_COUNTRY_CODE: "usAPHISForm.usaphisCountryCode",
    USAPHIS_POSTAL_CODE: "usAPHISForm.usaphisPostalCode",
    USAPHIS_TEL_NO: "usAPHISForm.usaphisTelNo",
    DELIVERY_IN_U_S: "usAPHISForm.deliveryInUS",
    IMPORT_PERMIT_NUMBERS: "usAPHISForm.importPermitNumbers",
    USAPHIS_REMARKS: "usAPHISForm.usaphisRemarks",
    USAPHIS_PRINT_NAME: "usAPHISForm.usaphisPrintName",
    USAPHIS_TITLE: "usAPHISForm.usaphisTitle",
    VET_DATE: "usAPHISForm.vetDate",
    USAPHIPORT_OF_ENTRY: "usAPHISForm.usaphiportOfEntry"
  },
  NBCIUSInvoiceUSAPHISAnimalFormDTO: {
    TITLE: "nBCIUSInvoiceUSAPHISAnimalFormDTO.title",
    US_A_P_H_I_S_FORM: "nBCIUSInvoiceUSAPHISAnimalFormDTO._usAPHISFormId",
    COMMON_NAME: "nBCIUSInvoiceUSAPHISAnimalFormDTO.commonName",
    ANIMAL_NUMBER: "nBCIUSInvoiceUSAPHISAnimalFormDTO.animalNumber",
    PURPOSE_OF_IMPORTATION: "nBCIUSInvoiceUSAPHISAnimalFormDTO.purposeOfImportation",
    SEX: "nBCIUSInvoiceUSAPHISAnimalFormDTO.sex"
  },
  NaftaForm: {
    TITLE: "naftaForm.title",
    BLANKET_PERIOD_FROM: "naftaForm.blanketPeriodFrom",
    BLANKET_PERIOD_TO: "naftaForm.blanketPeriodTo",
    COMPANY: "naftaForm.company",
    TITLE: "naftaForm.title",
    NAFTA_DATE: "naftaForm.naftaDate",
    N_A_F_T_APARTY_NAME: "naftaForm.naftapartyName",
    CONTACT_TEL_NUM: "naftaForm.contactTelNum",
    CONTACT_FAX: "naftaForm.contactFax",
    EMAIL: "naftaForm.email",
    CERIFIER_TYPE: "naftaForm.cerifierType"
  },
  CiForm: {
    TITLE: "ciForm.title",
    CI_FORM_ID:"ciForm.id",
    PARTY_BILLED: "ciForm.partyBilled",
    PARTY_BILLED_OTHERS: "ciForm.partyBilledOthers",
    SHIPMENT_DATE: "ciForm.shipmentDate",
    ARRIVAL_DATE_TIME: "ciForm.arrivalDateTime",
    MARK_PACK_LOAD: "ciForm.markPackLoad",
    TERM_OF_SALE: "ciForm.termOfSale",
    REASON_FOR_EXPORTATION: "ciForm.reasonForExportation",
    TRANSACTION_PARTIES: "ciForm.transactionParties",
    FBI_PURCHASE_PRICE: "ciForm.fbiPurchasePrice",
    FFD_PURCHASE_PRICE: "ciForm.ffdPurchasePrice",
    BFI_PURCHASE_PRICE: "ciForm.bfiPurchasePrice",
    DFI_PURCHASE_PRICE: "ciForm.dfiPurchasePrice",
    GOODS_SOLD: "ciForm.goodsSold",
    ORIGIN_STATE_CODE: "ciForm.originStateCode",
    ORIGIN_COUNTRY_CODE: "ciForm.originCountryCode",
    DESTINATION_COUNTRY_CODE: "ciForm.destinationCountryCode",
    DESTINATION_STATE_CODE: "ciForm.destinationStateCode",
    TRANSPORT_TERMS_CODE: "ciForm.transportTermsCode",
    SHIPPER_REG_NO: "ciForm.shipperRegNo",
    TSCA_STATEMENT: "ciForm.tscaStatement",
    US_PRO_FORMA: "ciForm.usProForma",
    US_GOODS: "ciForm.usGoods",
    LOW_VALUE_NAFTA: "ciForm.lowValueNafta",
    US_SHIP_EXP: "ciForm.usShipExp",
    US_GOODS10219: "ciForm.usGoods10219",
    FDA_SUB: "ciForm.fdaSub",
    DECL_NEW_STMT: "ciForm.declNewStmt",
    DECL_ID: "ciForm.declid"
  },
  NBCIUSInvoiceCargoSummary: {
    TITLE: "nBCIUSInvoiceCargoSummary.title",
    CI_FORM: "nBCIUSInvoiceCargoSummary._ciFormId",
    REFERENCE: "nBCIUSInvoiceCargoSummary.reference",
    REFERENCE_NUMBER: "nBCIUSInvoiceCargoSummary.referenceNumber",
    CARGO_SUMMERY_CI_FORM_ID: "nBCIUSInvoiceCargoSummary.ciformid"
  },
  NBCIUSInvoiceSummaryReference: {
    INVOICE_SUMMARY: "nbciUSInvoiceShipmentSummary._invoiceSummaryId",
    TITLE: "NBCIUSInvoiceSummaryReference.title",
    ADD_DED_CODE: "NBCIUSInvoiceSummaryReference.adddedcode",
    REF_AMOUNT: "NBCIUSInvoiceSummaryReference.refamount",
    ADD_DED_IND: "NBCIUSInvoiceSummaryReference.addInd",
  },
}

export default NbciUsInvoiceKeys
