import  PartyMasterApiUrls  from '../../../../apiUrls/NBCIUSCAPartyMasterApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'


const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField,
      CngServerSideAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function NaNbciPartyMasterAutoCompleteField(props, codeMaintenanceType) {

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  // console.log(props)

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={false}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['inputPartyID']}
      lookupPageableProps={{
        customData: { "filters":filters},
        url: PartyMasterApiUrls.GET,
        label: 'inputPartyID',
        value: 'inputPartyID',
        filters: [
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

NaNbciPartyMasterAutoCompleteField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default NaNbciPartyMasterAutoCompleteField
